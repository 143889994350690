import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/sale-banner.jpg`;
const fathersDayBanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/desktop/fathers-day-banner.jpg`;
const aprbanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/april-asset.jpg`;


const SpecialOffersInstagram = () => {
  const getFirstCard = () => {
    const today = new Date();
    const aprPromo =
      today >= new Date(2024,3,1) &&
      today < new Date(2024,4,1);
    const fathersDayPromo =
      today >= new Date(2024,4,15) &&
      today < new Date(2024,5,14);
    if (fathersDayPromo) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={fathersDayBanner}
            imageAlt="DAD DESERVES TO DRIFT"
            header="DAD DESERVES TO DRIFT"
            subheader="GET 20% OFF A GIFT CARD"
            text="Give your dad or grad (or both) what they really want - a day at the track. Get 20% off a gift card, good for many of our classes and experiences."
            btnText="Call 888-345-4269 to book"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              Use promo code <span className="bold"> 24IG61520</span>. Must purchase card by 6/17/24. Not valid on M4 GT4 Experience, Race License School, Private Instruction and M Summer Competition.
            </p>
          </FlexCard>
          <br />
        </>
      );
    } else if (aprPromo) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={aprbanner}
            imageAlt="BRING A FRIEND. OR SOMEONE SLOWER."
            header="BRING A FRIEND. OR SOMEONE SLOWER."
            subheader="BUY ONE SEAT, GET ONE FREE AT INDY"
            text="Time to drive for bragging rights. Book a seat by April 30th at Indy, and get one free."
            btnText="Call 888-345-4269 to book now"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              Use promo code <span className="bold">24IG430BOGO</span>. Must purchase class by 4/30/24. Valid for Indianapolis Experiences only.
            </p>
          </FlexCard>
          <br />
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersInstagram;
